// src/firebase.js
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBwbu__QDKnhurrBAnXVcC8-Igd2TJRWjE",
  authDomain: "trivia-rn.firebaseapp.com",
  databaseURL: "https://trivia-rn.firebaseio.com",
  projectId: "trivia-rn",
  storageBucket: "trivia-rn.appspot.com",
  appId: "1:462152016494:android:75267acf6de78e3e9cbc7f",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const provider = new firebase.auth.GoogleAuthProvider();

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const database = firebase.database();
export const storage = firebase.storage();
export const googleProvider = provider;

export default firebase;
