import React, { useState } from "react";
import "./../index.css";
import Tooltip from "./Tooltip";
import { saveQuestions } from "./../storageOperations";
import { firestore } from "./../firebase";
import "firebase/firestore";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { FaGoogle, FaApple, FaUser } from "react-icons/fa";

const Block = (props) => {
  const type = props.type;
  const data = props.data;
  const [questions, setQuestions] = useState(props.questions || []);
  const action = props.action;
  const [sortConfigs, setSortConfigs] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [visibleCount, setVisibleCount] = useState(20);
  const [savingQuestions, setSavingQuestions] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [questionsStats, setQuestionStats] = useState(
    data.questionDifficultyMismatch
  );

  const setAllUsersOffline = async () => {
    try {
      const batch = firestore.batch();

      data.forEach((user) => {
        console.log("Setting user offline:", user.id);
        const userRef = firestore.collection("Users").doc(user.id);
        batch.update(userRef, { isOnline: false });
      });

      await batch.commit();
      data = [];
      console.log("All users set to offline.");
    } catch (error) {
      console.error("Error setting users offline:", error);
    }
  };

  const setUserOffline = async (id) => {
    const user = data.find((user) => user.id === id);
    if (
      user &&
      window.confirm(`Are you sure you want to set ${user.username} offline?`)
    ) {
      try {
        const userRef = firestore.collection("Users").doc(id);
        await userRef.update({ isOnline: false });
        console.log(`User ${user.username} set to offline:`, id);
      } catch (error) {
        console.error(`Error setting user ${user.username} offline:`, error);
      }
    }
  };

  const handleSort = (key) => {
    let newSortConfigs = Array.isArray(sortConfigs) ? [...sortConfigs] : [];
    const existingSortConfig = newSortConfigs.find(
      (config) => config.key === key
    );

    if (existingSortConfig) {
      if (existingSortConfig.direction === "ascending") {
        existingSortConfig.direction = "descending";
      } else {
        newSortConfigs = newSortConfigs.filter((config) => config.key !== key);
      }
    } else {
      newSortConfigs.push({ key, direction: "ascending" });
    }

    setSortConfigs(newSortConfigs);
  };

  const handleCategoryFilterChange = (event) => {
    setCategoryFilter(event.target.value);
  };

  const getSortIcon = (key) => {
    const sortConfig = sortConfigs.find((config) => config.key === key);
    if (!sortConfig) {
      return null;
    }
    return sortConfig.direction === "ascending" ? (
      <ion-icon name="caret-up-outline" style={{ color: "white" }}></ion-icon>
    ) : (
      <ion-icon name="caret-down-outline" style={{ color: "white" }}></ion-icon>
    );
  };

  const sortedData = (data) => {
    if (!Array.isArray(data)) return [];
    const sortableItems = [...data];
    sortConfigs.forEach(({ key, direction }) => {
      sortableItems.sort((a, b) => {
        let aValue = a[key];
        let bValue = b[key];

        // Calculate total_answers if the key is total_answers
        if (key === "total_answers") {
          aValue = a.correct_count + a.wrong_count;
          bValue = b.correct_count + b.wrong_count;
        }

        if (aValue < bValue) {
          return direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    });
    return sortableItems;
  };

  const filteredData = (data) => {
    if (!Array.isArray(data)) return [];
    let filteredItems = data;

    if (categoryFilter !== "") {
      filteredItems = data.filter((item) => {
        return item.category === categoryFilter;
      });
    }

    if (searchQuery !== "") {
      filteredItems = filteredItems.filter((item) => {
        const question = questions.find((q) => q.id === item.id) || {};
        const searchText = searchQuery.toLowerCase();
        return (
          question.question.toLowerCase().includes(searchText) ||
          question.correct.toLowerCase().includes(searchText) ||
          question.wrong1.toLowerCase().includes(searchText) ||
          question.wrong2.toLowerCase().includes(searchText) ||
          question.wrong3.toLowerCase().includes(searchText)
        );
      });
    }

    return filteredItems;
  };

  const handleEdit = (question) => {
    setEditingQuestion({ ...question });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditingQuestion((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    const updatedQuestions = questions.map((q) =>
      q.id === editingQuestion.id ? { ...editingQuestion } : q
    );
    setQuestions(updatedQuestions);

    // Save the updated questions to Firebase Storage
    setSavingQuestions(true);
    await saveQuestions(updatedQuestions);
    setSavingQuestions(false);
    setEditingQuestion(null);

    // Update the last modified timestamp in Firestore
    // const lastModified = firebase.firestore.Timestamp.now();
    // await firestore
    //   .collection("settings")
    //   .doc("questions")
    //   .set({ lastModified });

    // // Update local storage
    // localStorage.setItem("questions", JSON.stringify(updatedQuestions));
    // localStorage.setItem(
    //   "questions_last_modified",
    //   lastModified.toDate().toISOString()
    // );

    // Optionally, if needed, update the question stats in the data prop
    const updatedQuestionStats = questionsStats.map((item) =>
      item.id === editingQuestion.id
        ? { ...item, difficulty: editingQuestion.difficulty }
        : item
    );
    setQuestionStats(updatedQuestionStats);
  };

  const resetStats = async (id) => {
    try {
      await firestore
        .collection("QuestionStats")
        .doc(id)
        .update({
          correct_count: 0,
          wrong_count: 0,
          difficulty: questions.find((q) => q.id === id).difficulty,
        });
      const questionsStatsTmp = [...questionsStats];
      questionsStatsTmp.find((q) => q.id === id).correct_count = 0;
      questionsStatsTmp.find((q) => q.id === id).wrong_count = 0;
      setQuestionStats(questionsStatsTmp);
      console.log("Stats reset successfully.");
    } catch (error) {
      console.error("Error resetting stats:", error);
    }
  };
  const renderQuestionsStatsTable = () => {
    const {
      totalQuestions,
      correctCount,
      wrongCount,
      generalStats,
      harryPotterStats,
    } = data;

    const filteredQuestions = filteredData(questionsStats);
    const sortedQuestions = sortedData(filteredQuestions);
    const visibleQuestions = sortedQuestions.slice(0, visibleCount);

    const chartData = [
      {
        name: "Total Questions",
        GENERAL: generalStats.total,
        HARRY_POTTER: harryPotterStats.total,
      },
      {
        name: "Correct Answers",
        GENERAL: generalStats.correct,
        HARRY_POTTER: harryPotterStats.correct,
      },
      {
        name: "Wrong Answers",
        GENERAL: generalStats.wrong,
        HARRY_POTTER: harryPotterStats.wrong,
      },
    ];

    const difficultyData = questions.reduce((acc, q) => {
      const difficulty = q.difficulty || "Unknown";
      if (!acc[difficulty]) {
        acc[difficulty] = 0;
      }
      acc[difficulty]++;
      return acc;
    }, {});

    const difficultyChartData = Object.keys(difficultyData).map((key) => ({
      difficulty: key,
      count: difficultyData[key],
    }));

    return (
      <>
        <div className="chart-container">
          <h2>Questions Statistics</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={chartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <RechartsTooltip />
              <Legend />
              <Bar dataKey="GENERAL" fill="#8884d8" />
              <Bar dataKey="HARRY_POTTER" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="chart-container">
          <h2>Question Difficulty Distribution</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={difficultyChartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="difficulty" />
              <YAxis />
              <RechartsTooltip />
              <Legend />
              <Bar dataKey="count" fill="#ffc658" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="stats-header">
          <h2>Total Questions: {totalQuestions}</h2>
          <h3>Correct Answers: {correctCount}</h3>
          <h3>Wrong Answers: {wrongCount}</h3>
          <div>
            <label htmlFor="categoryFilter">Filter by Category: </label>
            <select
              id="categoryFilter"
              value={categoryFilter}
              onChange={handleCategoryFilterChange}
            >
              <option value="">All</option>
              <option value="GENERAL">GENERAL</option>
              <option value="HARRY_POTTER">HARRY_POTTER</option>
            </select>
          </div>
          <div>
            <label htmlFor="searchQuery">Search: </label>
            <input
              type="text"
              id="searchQuery"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search questions..."
            />
          </div>
        </div>
        <button onClick={props.handleUpdateLastModified} className="update">
          Update Last Modified
        </button>
        {editingQuestion && (
          <div className="editForm">
            <h3>Edit Question</h3>
            <label>
              Question:
              <input
                type="text"
                name="question"
                value={editingQuestion.question}
                onChange={handleChange}
              />
            </label>
            <label>
              Correct Answer:
              <input
                type="text"
                name="correct"
                value={editingQuestion.correct}
                onChange={handleChange}
              />
            </label>
            <label>
              Wrong Answer 1:
              <input
                type="text"
                name="wrong1"
                value={editingQuestion.wrong1}
                onChange={handleChange}
              />
            </label>
            <label>
              Wrong Answer 2:
              <input
                type="text"
                name="wrong2"
                value={editingQuestion.wrong2}
                onChange={handleChange}
              />
            </label>
            <label>
              Wrong Answer 3:
              <input
                type="text"
                name="wrong3"
                value={editingQuestion.wrong3}
                onChange={handleChange}
              />
            </label>
            <label>
              Difficulty:
              <input
                type="number"
                name="difficulty"
                value={editingQuestion.difficulty}
                onChange={handleChange}
                min="1"
                max="15"
              />
            </label>
            {savingQuestions ? (
              "Saving..."
            ) : (
              <div>
                <button className="save" onClick={handleSave}>
                  Save
                </button>
                <button
                  className="cancel"
                  onClick={() => setEditingQuestion(null)}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        )}
        <table className="blockTable" id="questions">
          <thead>
            <tr>
              <th onClick={() => handleSort("id")}>
                Question ID {getSortIcon("id")}
              </th>
              <th onClick={() => handleSort("difficulty")}>
                Difficulty {getSortIcon("difficulty")}
              </th>
              <th onClick={() => handleSort("correct_count")}>
                Correct Count {getSortIcon("correct_count")}
              </th>
              <th onClick={() => handleSort("wrong_count")}>
                Wrong Count {getSortIcon("wrong_count")}
              </th>
              <th onClick={() => handleSort("total_answers")}>
                Total Answers {getSortIcon("total_answers")}
              </th>
              <th onClick={() => handleSort("correctRatio")}>
                Correct Ratio {getSortIcon("correctRatio")}
              </th>
              <th onClick={() => handleSort("category")}>
                Category {getSortIcon("category")}
              </th>
              <th className="actions">Actions</th>
            </tr>
          </thead>

          <tbody>
            {visibleQuestions.map((item, index) => {
              const question = questions.find((q) => q.id === item.id) || {};
              const totalAnswers = item.correct_count + item.wrong_count; // Calculate total answers

              const tooltipContent = (
                <div>
                  <strong>Question:</strong> {question.question}
                  <br />
                  <strong>Correct:</strong> {question.correct}
                  <br />
                  <strong>Wrong 1:</strong> {question.wrong1}
                  <br />
                  <strong>Wrong 2:</strong> {question.wrong2}
                  <br />
                  <strong>Wrong 3:</strong> {question.wrong3}
                </div>
              );
              return (
                <tr key={index}>
                  <td>
                    <Tooltip content={tooltipContent}>
                      <span>{item.id}</span>
                    </Tooltip>
                  </td>
                  <td>{question.difficulty}</td>
                  <td>{item.correct_count}</td>
                  <td>{item.wrong_count}</td>
                  <td>{totalAnswers}</td>
                  <td>{(item.correctRatio * 100).toFixed(2)}%</td>
                  <td>{item.category}</td>
                  <td className="actions">
                    <button
                      className="edit"
                      onClick={() => handleEdit(question)}
                    >
                      Edit
                    </button>
                    <button
                      className="reset"
                      onClick={() => resetStats(item.id)}
                    >
                      Reset Stats
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {visibleCount < sortedQuestions.length && (
          <div>
            <button onClick={() => setVisibleCount(visibleCount + 20)}>
              Load More
            </button>
            <button onClick={() => setVisibleCount(sortedQuestions.length)}>
              Load All
            </button>
          </div>
        )}
      </>
    );
  };

  const renderOnlineBlock = () => {
    const sortedUsers = [...data].sort((a, b) => {
      if (a.lastOnline && b.lastOnline) {
        return a.lastOnline.seconds - b.lastOnline.seconds;
      }
      return 0;
    });
    return (
      <div>
        <button onClick={setAllUsersOffline} className="set-offline-button">
          Set All Users Offline
        </button>
        <table className="blockTable" id="onlineTable">
          <thead>
            <tr>
              <th class="type"></th>
              <th>שם</th>
              <th>מפתח</th>
              <th>Last Online (lapsed time)</th>
              <th>Set Offline</th>
            </tr>
          </thead>
          <tbody>
            {sortedUsers.map((user, index) => (
              <tr key={user.id}>
                <td>
                  {user.type == "anonymous" ? (
                    <FaUser />
                  ) : user.type == "google" ? (
                    <FaGoogle style={{ color: "#EA4335" }} />
                  ) : (
                    <FaApple />
                  )}
                </td>
                <td>{user.username}</td>
                <td>
                  <a
                    href={`https://console.firebase.google.com/u/0/project/trivia-rn/firestore/databases/-default-/data/~2FUsers~2F${user.id}`}
                    target="_blank"
                  >
                    {user.id}
                  </a>
                </td>
                <td>
                  {user.lastOnline
                    ? `${new Date(
                        user.lastOnline.seconds * 1000
                      ).toLocaleString("he-IL")} (${getTimeLapsed(
                        user.lastOnline.seconds
                      )} ago)`
                    : "N/A"}
                </td>
                <td>
                  <ion-icon
                    name="close"
                    style={{
                      color: "red",
                      fontSize: "20pt",
                      cursor: "pointer",
                    }}
                    onClick={() => setUserOffline(user.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const getTimeLapsed = (timestamp) => {
    const now = new Date().getTime();
    const lapsed = now - timestamp * 1000;
    const seconds = Math.floor(lapsed / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) {
      return `${days} ${days == 1 ? "day" : "days"}`;
    }
    if (hours > 0) {
      return `${hours} ${hours == 1 ? "hour" : "hours"}`;
    }
    if (minutes > 0) {
      return `${minutes} ${minutes == 1 ? "minute" : "minutes"}`;
    }
    return `${seconds} ${seconds == 1 ? "second" : "seconds"}`;
  };

  const renderGamesTable = () => {
    const games = data;
    return games.map((game, index) => {
      return (
        <tr key={game.key}>
          <td>{game.users.admin.username}</td>
          <td>
            {game.users.partner !== undefined
              ? game.users.partner.username
              : "---"}
          </td>
          <td>
            <span
              style={{
                color: game.details.type === 0 ? "Classic" : "Combo",
              }}
            >
              {game.details.type === 0 ? "קלאסי" : "קומבו"}
            </span>
          </td>
          <td>
            <i>{convertEpochToSpecificTimezone(game.time * 1000, 3)}</i>
          </td>
          <td>
            <a
              href={`https://console.firebase.google.com/u/0/project/trivia-rn/database/trivia-rn/data/~2Fgames~2F${game.key}`}
              target="_blank"
            >
              {game.key}
            </a>
          </td>
        </tr>
      );
    });
  };

  const renderScoresTable = () => {
    const classic = data.classic;
    const frenzy = data.frenzy;
    return classic.map((values, index) => {
      return (
        <tr>
          <td>{values.rank}</td>
          <td>{values.username}</td>
          <td>{values.score}</td>
          <td>
            <ion-icon
              name="close"
              style={{ color: "red", fontSize: "20pt", cursor: "pointer" }}
              onClick={() => action(values.key)}
            />
          </td>
          <td>{frenzy[index].rank}</td>
          <td>{frenzy[index].username}</td>
          <td>{frenzy[index].score}</td>
          <td>
            <ion-icon
              name="close"
              style={{ color: "red", fontSize: "20pt", cursor: "pointer" }}
              onClick={() => action(frenzy[index].key)}
            />
          </td>
        </tr>
      );
    });
  };

  const convertEpochToSpecificTimezone = (timestamp, offset) => {
    // Check if the timestamp is in seconds or milliseconds
    const timeInMs =
      typeof timestamp === "string" ? parseInt(timestamp) * 1000 : timestamp;

    var d = new Date(timeInMs);
    var utc = d.getTime() + d.getTimezoneOffset() * 60000; //This converts to UTC 00:00
    var nd = new Date(utc + 3600000 * offset);
    return `${nd.getDate().toString().padStart(2, "0")}/${(nd.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${nd.getFullYear().toString().slice(-2)} ${nd
      .getHours()
      .toString()
      .padStart(2, "0")}:${nd.getMinutes().toString().padStart(2, "0")}:${nd
      .getSeconds()
      .toString()
      .padStart(2, "0")}`;
  };

  switch (type) {
    case "online":
      return (
        <div className="block">
          <h1>מחוברים ({data.length})</h1>
          {renderOnlineBlock()}
        </div>
      );
    case "games":
      return (
        <div className="block">
          <h1>משחקים ({data.length})</h1>
          <table className="blockTable" id="games">
            <tr>
              <td>שם אדמין</td>
              <td>שם פרטנר</td>
              <td>סוג משחק</td>
              <td>נוצר</td>
              <td>מפתח</td>
            </tr>
            {renderGamesTable()}
          </table>
        </div>
      );
    case "scores":
      return (
        <div className="block">
          <h1>טבלת שיאים</h1>
          <table className="blockTable" id="scores">
            <tr>
              <td colspan="4">קלאסי</td>
              <td colspan="4">טירוף</td>
            </tr>
            <tr>
              <td></td>
              <td>שם</td>
              <td>ניקוד</td>
              <td>פעולות</td>
              <td></td>
              <td>שם</td>
              <td>ניקוד</td>
              <td>פעולות</td>
            </tr>
            {renderScoresTable()}
          </table>
        </div>
      );
    case "questions":
      return (
        <div className="block">
          <h1>Question Statistics</h1>
          {renderQuestionsStatsTable()}
        </div>
      );
    default:
      return <div />;
  }
};

export default Block;
