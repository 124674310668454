import { storage, firestore } from "./firebase";
import firebase from "firebase/app";

const LOCAL_STORAGE_KEY = "questions_last_modified";

export const fetchQuestions = async () => {
  try {
    // Get last modified timestamp from Firestore
    const settingsDoc = await firestore
      .collection("settings")
      .doc("questions")
      .get();
    const lastModified = settingsDoc.data().lastModified.toDate();

    // Get last modified timestamp from local storage
    const localLastModified = new Date(localStorage.getItem(LOCAL_STORAGE_KEY));

    if (
      localLastModified &&
      localLastModified.getTime() === lastModified.getTime()
    ) {
      // If the local timestamp matches the Firestore timestamp, use the cached questions
      const cachedQuestions = localStorage.getItem("questions");
      if (cachedQuestions) {
        return JSON.parse(cachedQuestions).map((question) => ({
          ...question,
          question: decodeBase64(question.question),
          correct: decodeBase64(question.correct),
          wrong1: decodeBase64(question.wrong1),
          wrong2: decodeBase64(question.wrong2),
          wrong3: decodeBase64(question.wrong3),
        }));
      }
    }

    // Otherwise, fetch the questions from Firebase Storage
    const fileRef = storage.ref("Questions/questions.json");
    const url = await fileRef.getDownloadURL();
    const response = await fetch(url, { method: "GET" });
    const data = await response.json();

    // Store the questions and last modified timestamp in local storage
    localStorage.setItem("questions", JSON.stringify(data));
    localStorage.setItem(LOCAL_STORAGE_KEY, lastModified.toISOString());

    return data.map((question) => ({
      ...question,
      question: decodeBase64(question.question),
      correct: decodeBase64(question.correct),
      wrong1: decodeBase64(question.wrong1),
      wrong2: decodeBase64(question.wrong2),
      wrong3: decodeBase64(question.wrong3),
    }));
  } catch (error) {
    console.error("Error fetching questions:", error);
    return [];
  }
};

export const saveQuestions = async (questions) => {
  try {
    const fileRef = storage.ref("Questions/questions.json");
    const encodedQuestions = questions.map((question) => ({
      ...question,
      question: encodeBase64(question.question),
      correct: encodeBase64(question.correct),
      wrong1: encodeBase64(question.wrong1),
      wrong2: encodeBase64(question.wrong2),
      wrong3: encodeBase64(question.wrong3),
    }));
    const blob = new Blob([JSON.stringify(encodedQuestions)], {
      type: "application/json",
    });
    await fileRef.put(blob);

    // Update the last modified timestamp in Firestore
    // const lastModified = firebase.firestore.Timestamp.now();
    // await firestore
    //   .collection("settings")
    //   .doc("questions")
    //   .set({ lastModified });

    // Update local storage
    localStorage.setItem("questions", JSON.stringify(questions));
    // localStorage.setItem(
    //   "questions_last_modified",
    //   lastModified.toDate().toISOString()
    // );

    console.log("Questions saved successfully.");
  } catch (error) {
    console.error("Error saving questions:", error);
  }
};

export const updateLastModified = async () => {
  try {
    const lastModified = firebase.firestore.Timestamp.now();
    await firestore
      .collection("settings")
      .doc("questions")
      .update({ lastModified });
    console.log("Last modified timestamp updated.");
  } catch (error) {
    console.error("Error updating last modified timestamp:", error);
  }
};

const decodeBase64 = (str) => {
  try {
    return decodeURIComponent(escape(atob(str)));
  } catch (e) {
    return str; // Return the original string if decoding fails
  }
};

const encodeBase64 = (str) => {
  try {
    return btoa(unescape(encodeURIComponent(str)));
  } catch (e) {
    return str; // Return the original string if encoding fails
  }
};
