// src/App.js
import React, { useState, useEffect } from "react";
import "./App.css";
import Dashboard from "./components/Dashboard";
import firebase, { auth, googleProvider } from "./firebase";
import loader from "./images/loader.gif";

const App = () => {
  const [firebaseLoaded, setFirebaseLoaded] = useState(false);

  useEffect(() => {
    const loadFirebase = async () => {
      auth.onAuthStateChanged((user) => {
        if (user && user.email === "liavkuperman@gmail.com") {
          setFirebaseLoaded(true);
        } else {
          auth.signInWithRedirect(googleProvider);
        }
      });

      auth
        .getRedirectResult()
        .then((result) => {
          if (result.credential) {
            if (result.user.email === "liavkuperman@gmail.com") {
              setFirebaseLoaded(true);
            }
          }
        })
        .catch((error) => {
          console.error("Error during authentication", error);
        });
    };

    loadFirebase();
  }, []);

  return (
    <div className="App">
      {firebaseLoaded ? (
        <Dashboard />
      ) : (
        <div className="dashboard">
          <img src={loader} />
        </div>
      )}
    </div>
  );
};

export default App;
