import React, { useState, useEffect } from "react";
import loader from "./../images/loader.gif";
import Block from "./Block";
import "./../index.css";
import { firestore, database } from "./../firebase";
import {
  fetchQuestions,
  saveQuestions,
  updateLastModified,
} from "./../storageOperations";

const Dashboard = (props) => {
  const [online, setOnline] = useState([]);
  const [games, setGames] = useState([]);
  const [stats, setStats] = useState([]);
  const [scores, setScores] = useState({ classic: [], frenzy: [] });
  const [questionStats, setQuestionStats] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const fetchedQuestions = await fetchQuestions();
      setQuestions(fetchedQuestions);

      const statsTmp = await fetchQuestionStats();
      setQuestionStats(statsTmp);

      // Setup real-time listeners for online users and games
      const unsubscribeOnline = firestore
        .collection("Users")
        .where("isOnline", "==", true)
        .onSnapshot((snapshot) => {
          const onlineTmp = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            onlineTmp.push({
              username: data.username,
              id: doc.id,
              lastOnline: data.lastOnline ? data.lastOnline : null,
              type: data.type,
            });
          });
          setOnline(onlineTmp);
        });

      const unsubscribeGames = database.ref("games").on("value", (snapshot) => {
        const resultObj = snapshot.val();
        const gamesTmp = resultObj
          ? Object.keys(resultObj).map((key) => ({ key, ...resultObj[key] }))
          : [];
        setGames(gamesTmp);
      });

      const scoresTmp = await loadScores();
      setScores(scoresTmp);

      setIsLoading(false);

      // Cleanup function to unsubscribe from real-time updates
      return () => {
        unsubscribeOnline();
        unsubscribeGames();
      };
    };

    fetchData();
  }, []);

  const fetchQuestionStats = async () => {
    const statsTmp = {
      totalQuestions: 0,
      correctCount: 0,
      wrongCount: 0,
      generalStats: { total: 0, correct: 0, wrong: 0 },
      harryPotterStats: { total: 0, correct: 0, wrong: 0 },
      questionDifficultyMismatch: [],
    };

    const questionStats = await firestore.collection("QuestionStats").get();

    questionStats.forEach((doc) => {
      const data = doc.data();
      const { difficulty, category } = data;
      const correct_count = data.correct_count || 0;
      const wrong_count = data.wrong_count || 0;

      statsTmp.totalQuestions++;
      statsTmp.correctCount += correct_count;
      statsTmp.wrongCount += wrong_count;

      if (category === "GENERAL") {
        statsTmp.generalStats.total += correct_count + wrong_count;
        statsTmp.generalStats.correct += correct_count;
        statsTmp.generalStats.wrong += wrong_count;
      } else if (category === "HARRY_POTTER") {
        statsTmp.harryPotterStats.total += correct_count + wrong_count;
        statsTmp.harryPotterStats.correct += correct_count;
        statsTmp.harryPotterStats.wrong += wrong_count;
      }

      const totalAnswers = correct_count + wrong_count;
      const correctRatio = totalAnswers ? correct_count / totalAnswers : 0;

      if (true) {
        statsTmp.questionDifficultyMismatch.push({
          id: doc.id,
          difficulty,
          correct_count,
          wrong_count,
          correctRatio,
          category,
        });
      }
    });

    return statsTmp;
  };

  const loadScores = async () => {
    const scores = { classic: [], frenzy: [] };
    const snapshotClassic = await firestore
      .collection("TopClassicScores")
      .get();
    const snapshotFrenzy = await firestore.collection("TopFrenzyScores").get();
    snapshotClassic.forEach((doc) => {
      const data = doc.data();
      scores.classic.push({
        rank: data.Rank,
        score: data.Score,
        userId: data.UserId,
        username: data.Username,
      });
    });
    snapshotFrenzy.forEach((doc) => {
      const data = doc.data();
      scores.frenzy.push({
        rank: data.Rank,
        score: data.Score,
        userId: data.UserId,
        username: data.Username,
      });
    });
    scores.classic.sort((a, b) => a.rank - b.rank);
    scores.frenzy.sort((a, b) => a.rank - b.rank);
    return scores;
  };

  const deleteScore = async (key) => {
    if (window.confirm("בטוח שתרצה למחוק את הניקוד הזה?")) {
      await database.ref("scores/" + key).remove();
    }
  };

  const handleUpdateLastModified = async () => {
    await updateLastModified();
    alert("Last modified timestamp updated.");
  };

  return isLoading ? (
    <div className="dashboard">
      <img src={loader} />
    </div>
  ) : (
    <div className="dashboard">
      <Block type="online" data={online} />
      <Block type="games" data={games} />
      <Block type="scores" data={scores} deleteScore={deleteScore} />
      <Block
        type="questions"
        data={questionStats}
        questions={questions}
        setQuestions={setQuestions}
        handleUpdateLastModified={handleUpdateLastModified}
      />
    </div>
  );
};

export default Dashboard;
