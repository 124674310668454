// Tooltip.js
import React from "react";
import "./../Tooltip.css"; // Create a CSS file for styling

const Tooltip = ({ content, children }) => {
  const [visible, setVisible] = React.useState(false);
  const [coords, setCoords] = React.useState({ x: 0, y: 0 });

  const showTooltip = (e) => {
    const x = e.clientX;
    const y = e.clientY;
    setCoords({ x, y });
    setVisible(true);
  };

  const hideTooltip = () => {
    setVisible(false);
  };

  return (
    <div
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      onMouseMove={showTooltip}
      style={{ position: "relative", display: "inline-block" }}
    >
      {children}
      {visible && (
        <div className="tooltip" style={{ top: coords.y, left: coords.x }}>
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
